import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import eEventTypes from '../enums/eEventTypes';

export default class ShowStartScreenState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }

  start() {
    super.start();
    GlobalDispatcher.dispatch(eEventTypes.EET_START_SCREEN__SHOW);
    GlobalDispatcher.add(eEventTypes.EET_START_SCREEN__HIDE, this.stop.bind(this));
    if (EntryPoint.GameModel.isFreeSpinsMode) {
      OPWrapperService.infoPanel.setGameState(OPUtility.ControllerStatistic.gameStateTypes.EGST_FREESPINS_GAME);

      EntryPoint.GameModel.freespinsEnabled = true;
    }

  }

  stop() {
    if (!this.active) return;
    this.complete();
  }

  onEnd() {
    super.onEnd();
  }
}
