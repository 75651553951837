const colors = {
  spin: {
    default: 'linear-gradient(324.54deg, #FF4D4D 20.8%, #FF971D 79.2%)',
    hover: 'linear-gradient(324.54deg, #ff5454 20.8%, #fc9b2a 79.2%)',
    pressed: 'linear-gradient(324.54deg, #d34242 20.8%, #e38619 79.2%)',
    disabled: 'linear-gradient(324.54deg, #d34242 20.8%, #e38619 79.2%)',
  }
}

export default {
  H: {
    // colors,
    offsets: {
      tournaments_panel: { top: 125, left: -1430 }
    }
  },
  V: {
    // colors,
    offsets: {
      top: 0,
      left: 0,
    }
  }
}
